<template>
    <PageLayout>
        <template v-slot:side>
            <v-col cols="12" class="pa-0">
                <v-icon color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
            </v-col>
            <v-col cols="12" class="pa-0 pt-5">
                <v-card class="side-block" >
                    <div>
                        <div class="d-flex">
                            <div class="pa-4 participants mr-auto">
                                {{  $t('folders') }}
                            </div>
                            <div class="pa-4">
                                <v-icon
                                    size="18"
                                    color="secondary"
                                    class="add_icon"
                                    @click="addFolder(null, [])"
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                            </div>
                        </div>
                    </div>

                    <v-card-text class="pt-1 pl-2">
                        <el-tree v-if="showTree" ref="tree" :data="folderItems" node-key="id" draggable
                                 icon-class="mdi mdi-chevron-right" :filter-node-method="filterNode"
                                 :props="defaultProps" class="categories-tree elevation-0 pt-0 mt-0"
                                 :empty-text="$t('loading_please_wait')" :expand-on-click-node="false"
                                 @node-drop="handleDrop">
                        <span slot-scope="{ node, data }" class="custom-tree-node container_tree align-center">
                            <div class="d-flex">

                                <span>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   x-small
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="addFolder(node, data)">
                                                <v-list-item-title>{{ $t('add') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="editFolder(node, data)">
                                                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="deleteFolder(node, data)">
                                                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>

                                </span>
                                <span @click="showFiles(data)"  class="mr-auto">
                                    {{ data.name }}
                                </span>
                            </div>
                        </span>
                        </el-tree>

                    </v-card-text>
                </v-card>
            </v-col>
        </template>

        <template>
            <div class="d-flex justify-space-between mb-10">
                <div class="d-flex align-center">
                    <h1>{{ select_folder.name ? select_folder.name : (filter_show ? $t('search_storage') : $t('storage')) }}</h1>
                </div>
                <div @click="document.getElementById('file').click()" v-show="!filter_show" class="text-right">
                    <v-btn class="infinity_button" color="secondary">
                        {{$t('upload') }}
                    </v-btn>
                </div>
            </div>
            <v-row>
                <v-col cols="12">
                    <v-form @submit.prevent="getStorages()">
                        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                            <v-row v-if="filter_show">
                                <v-col cols="12" sm="8">
                                    <ValidationProvider ref="storage" rules="min:1|max:255" v-slot="{ errors, valid }">
                                        <v-text-field v-model="storage" type="text" :error-messages="errors"
                                                      :label="$t('name')"
                                                      autocomplete="off"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      hide-details
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
                                        <v-date-picker ref="pickerDateContract" v-model="date"
                                                       :locale="lang"
                                                       hide-details
                                                       @change="dateDialog = false">
                                        </v-date-picker>
                                    </v-dialog>
                                    <ValidationProvider ref="dateContract" rules="date_format" v-slot="{ errors, valid }">
                                        <v-text-field v-model="date" :error-messages="errors"
                                                      :error="!valid"
                                                      :label="$t('date_created')"
                                                      readonly
                                                      @click.stop="dateDialog = !dateDialog"
                                                      @click:clear="date = null"
                                                      autocomplete="off"
                                                      outlined
                                                      solo
                                                      hide-details
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row  v-if="filter_show">
                                <v-col cols="12" sm="3" md="2">
                                    <v-select :value="perPage" :items="perPageItems"
                                              :label="$t('items_per_page')"
                                              @input="perPage = options.itemsPerPage = Number($event)"
                                              hide-details
                                              autocomplete="off"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :disabled="loading"
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="9" md="10"
                                       class="d-flex justify-center justify-sm-end align-self-center">
                                    <v-btn type="submit" :disabled="invalid || loading" class="infinity_button"
                                           :block="$vuetify.breakpoint.xsOnly" color="primary">
                                        {{ $t('search') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </ValidationObserver>
                    </v-form>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <ResizableTable
                        class="main-data-table"
                        :rows="storages"
                        :columns="filteredHeaders.map((item) => {
                        return {
                            ...item,
                            active: true,
                            prop: item.value,
                            label: item.text,
                            width: 'auto'
                        }
                    })"
                    >
                        <template v-slot:url="{ item }">
                            <div @click="downloadFile(item.url, item.name)" class="cursor_pointer">
                                <v-img  v-if="mimeTypeImage(item.type_mime)"
                                        :src="item.url"
                                        height="40" width="40"></v-img>

                                <v-icon class="text-center"
                                        height="40" width="40"
                                        x-large
                                        v-if="!mimeTypeImage(item.type_mime)">mdi-file-document-outline mdi-24px</v-icon>
                            </div>
                        </template>

                        <template v-slot:created_at="{ item }">
                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                        </template>
                        <template v-slot:name="{ item }">
                            <div @click="downloadFile(item.url, item.name)" class="cursor_pointer">{{ item.name }}</div>
                        </template>

                        <template v-slot:action="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        :href="item.url"
                                        download
                                        target="_blank"
                                        icon>
                                        <v-icon>mdi-file-download-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('download')}}</span>
                            </v-tooltip>
                        </template>
                    </ResizableTable>
                    <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
                </v-col>
            </v-row>

            <v-file-input hide-input prepend-icon=""
                          v-model="files"
                          id="file"
                          multiple
                          @change="previewFiles"
            >
            </v-file-input>

            <v-dialog

                persistent
                max-width="960px"
                transition="dialog-bottom-transition"

                v-if="folderData" v-model="dialogFolder"
            >

                <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ folderData.id ? $t('folder_editing') : $t('folder_creation') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogFolder()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                    <span :class="exist_translations[language] ? '' : 'red--text '">
                                        {{ language }}
                                    </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="folder" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="folderData.name" type="text" :disabled="loading"
                                                      :error="!valid" :error-messages="errors"
                                                      :label="$t('folder_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 pt-10 pb-7">
                            <v-btn :disabled="invalid || loading" :loading="loading"
                                   @click="saveFolder(folderData)"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogFolder()"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

            <v-dialog
                v-model="dialog_file"
                scrollable
                persistent
                transition="dialog-bottom-transition"
                max-width="500px"


            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('send_files', {'count': document_urls.length}) }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialog_file = false;document_urls=[];documents=[]">mdi-close</v-icon>
                        </div>
                    </v-toolbar>

                    <v-card-text style="height: 300px;" class="pa-0">
                        <v-list subheader>
                            <v-list-item v-for="document in document_urls" :key="document.id">
                                <v-list-item-icon>
                                    <v-img
                                        max-width="90"
                                        :alt="`${document.name}`"
                                        :src="document.url"
                                        max-height="60"
                                    ></v-img>
                                    <v-icon
                                        x-large
                                        v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="document.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-icon class="d-flex align-self-center mt-2">
                                    <v-btn
                                        icon
                                        @click="deleteFile(document)"
                                    >
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>

                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>


                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="SaveFile">
                            {{ $t('sent') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </PageLayout>
</template>
<script>

import { mapGetters } from "vuex"
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import debounce from "lodash/debounce";
import FilterView from "@/plugins/mixins/FilterView";

export default {
    name: "Storages",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            date: null,
            dateDialog: false,
            client: null,
            clientItems: [],
            clientSearching: null,
            loadingClients: false,
            storage: null,
            email: null,
            storages: [],
            storageItems: [],
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            sortBy: "id",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalStorages: 0,
            options: {
                ...FilterView.data().options,
                sortBy: ['id'],
            },
            loading: false,
            loadingCities: false,
            city: null,
            cityItems: [],
            citySearching: null,
            headers: [
                {
                    text: this.$t('review'),
                    align: "left",
                    sortable: true,
                    value: "url"
                },
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: true,
                    value: "author"
                },
                {
                    text: this.$t('date_created'),
                    align: "left",
                    sortable: true,
                    value: "created_at"
                },
            ],
            filter_show: false,
            showTree: true,
            folderItems: [],
            addFolderData: {},
            defaultProps: {
                children: 'children',
                label: 'name',
                isLeaf: 'leaf'
            },
            node: {},
            nodedata: {},
            dialogFolder:false,
            exist_translations: {},
            all_translations: true,
            tab: 0,
            language: null,
            progress: 0,

            tooltipShowMobile: false,
            tooltipShow: false,
            folder: null,
            loadingSaveFolder: false,

            folderData: [],
            select_folder: {
                id: null,
                name: null,
            },
            document,
            files: [],
            document_urls: [],
            dialog_file: false,


        };
    },
    computed: {
        ...mapGetters(['lang', 'listLanguages', 'itemsPerPage', 'perPageItems']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getStorages()
        this.getFolders()
    },
    watch: {
        options: {
            handler() {
                this.getStorages()
            },
            deep: false
        },
        clientSearching: debounce(function (val) {
            if (val && !this.client) {
                this.getClients(val)
            }
        }, 500)
    },
    methods: {
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
        },
        async getStorages() {
            this.loading = true;
            const {
                sortBy,
                sortDir: sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc !== undefined) {
                params.sortDir = sortDesc ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.storage) {
                params.storage = this.storage;
            }
            if (this.select_folder.id) {
                params.folder_id = this.select_folder.id;
            }
            if (this.date) {
                params.date = this.date;
            }
            if (this.client) {
                if (this.client.id) {
                    params.client = this.client.id
                } else {
                    params.client = this.client
                }
            }
            await this.$http
                .get("admin/file_storage", {
                    params: params,
                })
                .then(res => {
                    this.storages = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalStorages = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.storages = []
                    this.totalStorages = 0
                    this.$toastr.error(this.$t('failed_to_get_list_storages'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getClients(str) {
            if (str) {
                this.loadingClients = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.client = str
                }
                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then(res => {
                        this.clientItems = res.body.data
                    })
                    .catch(err => {
                        this.clientItems = []
                    })
                    .finally(end => {
                        this.loadingClients = false
                    })
            }
        },
        clearClients() {
            if (!this.client) {
                this.clientItems = []
            }
        },

        setLanguage(val) {
            this.language = this.languages[val]
        },
        redirectToClient(val) {
            if (val) {
                this.$router.push({
                    name: 'client.profile',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getFolders(parent) {
            this.loading = true
            let params = {}
            params.filter = 'all'

            if (this.folder) {
                params.folder = this.folder
            }
            if (parent !== null && parent >= 0) {
                params.parent = parent
            }
            await this.$http
                .get("admin/folder", {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.length > 0) {
                        this.folderItems = res.body.data
                    } else {
                        this.folderItems = []
                    }
                })
                .catch(err => {
                    this.folderItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_folders'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        showFiles(item){
            this.select_folder.id = item.id;
            this.select_folder.name = item.name;
            this.getStorages();
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        async handleDrop(draggingNode, dropNode, dropType, ev) {
            if (dropType === 'inner') {
                let notesInner = []
                draggingNode.data.parent_id = dropNode.data.id
                draggingNode.data.sort = (Number((dropNode.data.children.length - 1)) + 1)
                notesInner.push(draggingNode.data)
                await this.sortingFolders(dropNode.data.id, notesInner)
            }
            let parent = 0
            if (dropNode.parent.data.id !== undefined) {
                parent = dropNode.parent.data.id
            }
            let nodesPatrent = dropNode.parent.getChildren()
            for (let i in nodesPatrent) {
                nodesPatrent[i].parent_id = parent
                nodesPatrent[i].sort = (Number(i) + 1)
            }
            await this.sortingFolders(parent, nodesPatrent)
        },
        async reload() {
            await this.getCategories()
        },
        editFolder(node, data) {
            this.node = node
            this.nodedata = data
            this.dialogFolder = true
            this.folderData = Object.assign({}, data)
        },
        closeDialogFolder() {
            this.dialogFolder = false
            this.$nextTick(() => {
                this.folderData = {}
            })
        },
        addFolder(node, data) {
            this.dialogFolder = true
            this.node = node
            this.nodedata = data
            this.folderData = {
                id: 0,
                parent_id: (data && data.id) ? data.id : 0,
                name: null,
                sort: (data && data.children && data.children !== null) ? (data.children.length + 1) : 1,
                leaf: true,
                children: false
            }
        },
        async deleteFolder(node, data) {
            if (confirm(this.$t('delete_folder'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/folder/${data.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_deleted'))
                        this.$refs.tree.remove(data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async saveFolder(item) {
            var _this = this
            this.progress = 0
            this.loading = true
            this.loadingSaveFolder = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (item.name) {
                formData.append('folder', item.name)
            }
            if (item.parent_id) {
                formData.append('parent', item.parent_id)
            }
            if (item.sort >= 0) {
                if (this.node === null) {
                    item.sort = (Number(this.folderItems.length) + 1)
                }
                formData.append('sort', item.sort)
            }
            if (item.id) {
                // Save
                await this.$http
                    .put(`admin/folder/${item.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations

                            this.nodedata.name = item.name
                        }

                        this.dialogFolder  = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                        this.loadingSaveFolder = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/folder', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations

                            if (!this.nodedata.children) {
                                this.$set(this.nodedata, 'children', []);
                            }

                            item.id = res.body.data.id

                            if (this.node === null) {
                                this.folderItems.push(item)
                            } else {
                                this.nodedata.children.push(item)
                            }
                        }
                        this.dialogFolder = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                        this.loadingSaveFolder = false
                    })
            }
        },
        async sortingFolders(parent, nodes) {

            if (parent >= 0 && nodes.length > 0) {

                this.loading = true
                var formData = new FormData()

                if (parent > 0) {
                    formData.append('parent', parent)
                }

                for (let i in nodes) {
                    formData.append(`nodes[${i}][id]`, nodes[i].id)
                    formData.append(`nodes[${i}][sort]`, nodes[i].sort)
                }

                await this.$http
                    .post('admin/folder/sort', formData)
                    .then(res => {
                        this.$toastr.success(this.$t('folder_sorting_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_sorting_not_updated'))
                        if (err && err.body && err.body.message) {
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        previewFiles() {
            let i = this.document_urls.length
            let data = {}
            this.files.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.document_urls.push(data)
                i++
            })
            this.dialog_file = true
        },
        deleteFile(item) {
            let index = this.document_urls.indexOf(item)
            this.document_urls.splice(index, 1)
            if (this.document_urls.length === 0) {
                this.dialog_file = false;
                this.document_urls = [];
                this.documents = []
            }
        },
        async SaveFile() {
            var _this = this;
            let formData, blob;
            this.dialog_file = false;
            this.documents = []
            let is_image = false;


            for (const file of this.document_urls) {



                is_image = this.mimeTypeImage(file.type);

                formData = new FormData();
                if(this.select_folder.id){
                    formData.append('folder_id', this.select_folder.id)
                }
                formData.append('type_mime', file.type)
                formData.append('size', file.size)
                formData.append('file_name', file.name)
                formData.append('is_image', is_image ? 1 : null)
                file.blob = await this.convertBlobToBase64(file.blob)
                if (file.blob) {
                    blob = this.dataURL64toBlob(file.blob)
                    if (blob) {
                        formData.append('file', blob, file.type)
                    }
                }

                // Add
                this.$http
                    .post('admin/file_storage', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('file_been_added'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.document_urls = [];
                    })


            }
            this.getStorages();

        },
        downloadFile(url, file_name) {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },
    }
}
</script>
